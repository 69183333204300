import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

const Scard = ({ title, image, subtitle, actionUrl,handleCloseModal }) => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        borderRadius: "20px",
        width: "100%",
        bacgkroundColor: "transparent",
        boxShadow: "none",
      }}
    >
        <CardContent
          sx={{ display: "flex", direction: "row",cursor:'pointer' }}
          onClick={() => {
            navigate(actionUrl,{ replace: true });
            handleCloseModal(false);
          }}
        >
          <CardMedia
            component="img"
            sx={{ width: "50px", borderRadius: "20px" }}
            height="50px"
            image={image}
            alt={`Ch2o lebanon ${title}`}
          />
          <Box px={2} sx={{ width: "100%" }}>
            <Typography
              variant="body2"
              sx={{ color: "#001a72", textTransform: "uppercase" }}
            >
              {title}
            </Typography>
            <Typography pt={2} variant="caption">
              {subtitle && subtitle}
            </Typography>
          </Box>
        </CardContent>
    </Card>
  );
};
export default Scard;
