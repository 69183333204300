import { Container } from "@mui/system";
import Contents from "./Sections/Contents";
import Header from "../../components/Sections/Header";
import { Link, Typography } from "@mui/material";

const WaterSolution = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1"  href="/" color="inherit">
      Home
    </Link>,
    <Typography key="2">Water solutions</Typography>,
  ];
  return (
    <>
      <Header
        title="Water Solutions"
        breadcrumbs={breadcrumbs}
        description="CH2O develops tailored solutions to help you reach a water quality you desire and assist you in determining your physical and chemical water challenges."
      />
      <Container>
        <Contents />
      </Container>
    </>
  );
};
export default WaterSolution;
