import { Card, CardContent, Typography } from "@mui/material";
import "../../../assets/css/Ycard.css";

const Ycard = ({ title, text }) => {
  return (
    <Card sx={{ borderRadius:'20px',width:'100%' }}>
      <CardContent sx={{ borderLeft: "20px solid #FBAE3C"}}>
        <Typography variant="h6" sx={{ color:'black',textTransform:'uppercase' }}>{title}</Typography>
        <Typography pt={1} color="black" sx={{ opacity:0.9 }}>{text && text}</Typography>
      </CardContent>
    </Card>
  );
};
export default Ycard;
