import { Container } from "@mui/system";
import Header from "../../components/Sections/Header";
import {  Typography, Grid ,Skeleton, Box} from "@mui/material";
import Tcard from "../../components/cards/Tcard";
import useFetch from "../../hooks/useFetch";
import { useLocation,Link } from "react-router-dom";
import { useEffect } from "react";
import LightBox from "../../components/LightBox";

const URL_STORAGE = "https://admin.ch2olb.com";

const Inspiration = () => {
  const { error, isPending, data } = useFetch(
    "https://admin.ch2olb.com/api/getProjectBySlug/inspiration-gallery"
  );

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const breadcrumbs = [
    <Link key="1" to="/" style={{ color:'white' }}>
      Home
    </Link>,
    <Typography key="2" color="white">Inspiration Gallery</Typography>,
  ];
  return (
    <>
      <Header
        title="Inspiration Gallery"
        breadcrumbs={breadcrumbs}
        description="Browse our inspiration gallery and dream with your eyes open."
      />
      <Box my={10}>
      <Container>
        {isPending && (
          <Grid container spacing={1}>
            {" "}
            <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" sx={{ height:'250px',borderRadius:'10px' }}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" sx={{ height:'250px',borderRadius:'10px' }}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" sx={{ height:'250px',borderRadius:'10px' }}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <Skeleton variant="rectangular" sx={{ height:'250px',borderRadius:'10px' }}/>
            </Grid>
          </Grid>
        )}
        {data && <LightBox images={data.data.gallery} />}
      </Container>
      </Box>
    </>
  );
};
export default Inspiration;
