import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";

const useStyle = makeStyles({
  link: {
    color: "$001a72",
  },
  activeLink: {
    backgroundColor: "#001a72",
    color:'white',
    borderRadius: "10px",
    "&:hover": { backgroundColor: "#001a72", borderRadius: "10px" },
  },
});

const pages = [
  {
    name: "Products",
    url: "/products",
    activateWhen: ["products", "items", "item"],
  },
  // {
  //   name: "Solutions",
  //   url: "/water-solutions",
  //   activateWhen: ["water-solutions"],
  // },
  {
    name: "Work",
    url: "/work",
    activateWhen: ["work", "project"],
  },
  {
    name: "Inspiration",
    url: "/inspiration-gallery",
    activateWhen: ["inspiration-gallery"],
  },
  {
    name: "Services",
    url: "/services",
    activateWhen: ["services"],
  },
  // {
  //   name: "About",
  //   url: "/contactus",
  //   activateWhen: ["contactus"],
  // },
  {
    name: "Contact us",
    url: "/contactus",
    activateWhen: ["contactus"],
  },
];

export default function SwipeableTemporaryDrawer() {
  const classes = useStyle();
  const location = useLocation();
  const pathName = location.pathname.split("/");
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        height: "100%",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List sx={{ top: 0, py: 5, zIndex: 1000 }}>
        {pages.map((text, index) => (
          <ListItem key={index}>
            <ListItemButton
              className={
                text.activateWhen.includes(pathName[1])
                  ? classes.activeLink
                  : classes.link
              }
            >
              <ListItemText
                primary={
                  <Typography component="div" variant="h6" sx={{ color: "inherit" }} textAlign="center">
                    <Link to={text.url} style={{ textDecoration:'none',color:'inherit' }}> {text.name}</Link>
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Button onClick={toggleDrawer("right", true)} sx={{ color: "white" }}>
          <MenuIcon />
        </Button>
        <SwipeableDrawer
          anchor={`right`}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
