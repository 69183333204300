import React from "react";
import { Grid, Button, Box } from "@mui/material";
import Tcard from "../../components/cards/Tcard";
import bla from "../../assets/images/pool-equipement.jpg";
import bla1 from "../../assets/images/water-filtration.jpg";
import bla2 from "../../assets/images/plumbing.jpg";
import SubTitle from "../../components/Typography/SubTitle";
import Carousel from "react-grid-carousel";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Container } from "@mui/system";
import useFetch from "../../hooks/useFetch";
import Skelton from "../../components/cards/Tcard/Skelton";
const URL_STORAGE = "https://admin.ch2olb.com";

const Product = () => {
  const navigate = useNavigate();
  const { data, isPending } = useFetch(
    "https://admin.ch2olb.com/api/getAllMainCats"
  );
  const count = [1, 2, 3, 4];
  return (
    <Box
      sx={{
        mx: { sm: 2, md: 5 },
        borderRadius: "20px",
        py: 5,
      }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8}>
            <div
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <SubTitle subtitle="Products" />
            </div>
          </Grid>
          <Grid
            item
            xs={4}
            my="auto"
            sx={{ display: { xs: "none", md: "flex" } }}
            justifyContent="end"
          >
            <div
              data-aos="fade-left"
              data-aos-duration="1000"
            >
            <Button
              variant="contained"
              sx={{ fontSize: "14px", textTransform: "capitalize" }}
              endIcon={<ArrowForwardIcon />}
              onClick={() => {
                navigate("/products");
              }}
            >
              View All
            </Button>
            </div>
          </Grid>
          <Grid item xs={12} my={5}>
            <Carousel
              cols={3}
              rows={1}
              gap={10}
              loop
              responsiveLayout={[
                {
                  breakpoint: 1200,
                  cols: 3,
                },
                {
                  breakpoint: 990,
                  cols: 2,
                },
              ]}
            >
              {isPending &&
                count.map((res) => {
                  return (
                    <Carousel.Item key={res}>
                      <Skelton />
                    </Carousel.Item>
                  );
                })}
              {data &&
                data.data.map((res, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <div data-aos="zoom-in-up" data-aos-duration="1000">
                        <Tcard
                          title={res.name}
                          subtitle={`${res.categories_count} Categories`}
                          image={`${URL_STORAGE}/${res.photo}`}
                          urlAction={`/products/${res.slug}`}
                        />
                      </div>
                    </Carousel.Item>
                  );
                })}
            </Carousel>
          </Grid>
          <Grid
            item
            xs={12}
            my="auto"
            sx={{ display: { xs: "flex", md: "none" } }}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              sx={{ fontSize: "14px", textTransform: "capitalize" }}
              endIcon={<ArrowForwardIcon />}
              onClick={() => {
                navigate("/products");
              }}
            >
              View All
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Product;
