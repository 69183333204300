import { useEffect} from "react";
import { Container } from "@mui/system";
import { Grid, Typography, Divider, Box, Skeleton,Card,CardContent,CardMedia } from "@mui/material";
import { useParams, Link, useNavigate,useLocation } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import { Interweave } from "interweave";
import Header from "../../components/Sections/Header";
import useFetch from "../../hooks/useFetch";

const STORAGE_URL = "https://admin.ch2olb.com";

const Project = () => {
  const params = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  

  const { error, isPending, data } = useFetch(
    "https://admin.ch2olb.com/api/getProjectBySlug/" + params.slug
  );
  const breadcrumbs = [
    <Link to="/" style={{ textDecoration: "none", color: "white" }} key="1">
      Home
    </Link>,
    <Link to="/work" style={{ textDecoration: "none", color: "white" }} key="2">
      Work
    </Link>,
    <Link
      to={`/work/${data && data.data.content[0].main_work.slug}`}
      style={{ textDecoration: "none", color: "white" }}
      key="3"
    >
      {data ? data.data.content[0].main_work.name:<Skeleton width={100} variant="text" />}
    </Link>,
    <Typography key="4" color="white">{data ? data.data.content[0].name:<Skeleton width={100} variant="text" />}</Typography>,
  ];
  return (
    <>
      <Header
        title="Work"
        description="We are proud to share some of the success cases of our valued customers."
        breadcrumbs={breadcrumbs}
      />
      <Box my={10}>
      <Container>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              {error && <h1>Oops not found</h1>}
              {isPending && (
                <Card sx={{ width: "100%", borderRadius: "20px" }}>
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={300}
                    sx={{ borderRadius: "20px" }}
                  />
                  <CardContent>
                    <Skeleton width="50%" />
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="100%"
                      sx={{ mt: 5 }}
                    />
                    <Skeleton animation="wave" height={10} width="100%" />
                    <Skeleton animation="wave" height={10} width="100%" />
                    <Skeleton animation="wave" height={10} width="100%" />
                    <Skeleton animation="wave" height={10} width="60%" />
                  </CardContent>
                </Card>
              )}
              {data && (
                <Card sx={{ width: "100%", borderRadius: "20px" }}>
                  <ImageGallery items={data.data.gallery}   />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {data.data.content[0].name}
                    </Typography>
                    <Typography variant="body1" component="div">
                      <Interweave content={data.data.content[0].description} />
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Card sx={{ width: "100%", borderRadius: "20px" }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    More works
                  </Typography>
                  <Divider
                    sx={{
                      width: "100%",
                      backgroundColor: "#FBAE3C",
                      height: "1px",
                    }}
                  />
                  <Grid container my={2} spacing={1}>
                    {isPending && (
                      <>
                        <Grid item xs={4}>
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            height={`100px`}
                            sx={{ borderRadius: "10px" }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            height={`100px`}
                            sx={{ borderRadius: "10px" }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            height={`100px`}
                            sx={{ borderRadius: "10px" }}
                          />
                        </Grid>
                      </>
                    )}
                    {data &&
                      data.data.moreWork.map((res, index) => {
                        return (
                          <Grid item xs={4} key={index}>
                            <CardMedia
                              alt={`ch2o project of ${res.name}`}
                              image={`${STORAGE_URL}/${res.photo}`}
                              sx={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigate(`/project/${res.slug}`,{replace:true});
                              }}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
      </Box>
    </>
  );
};
export default Project;
