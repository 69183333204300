import React from "react";
import b1 from "../../assets/images/bg.webp";
import {
  Box,
  Container,
  Grid,
  Button,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import Dcard from "../../components/cards/Dcard";
import qualityLogo from "../../assets/images/pool-quality.jpg";
import maintenanceImg from "../../assets/images/pool-maintenance.jpg";
import newImg from "../../assets/images/new.jpg";
import newLImg from "../../assets/images/largenew.jpg";
import testingLogo from "../../assets/images/pool-testing-1.jpg";
import { useNavigate } from "react-router-dom";
import MainTitle from "../../components/Typography/MainTitle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion } from "framer-motion";
import bg from "../../assets/images/service_home_bg.jpg";
import line from "../../assets/images/line-1.svg";

const Services = () => {
  const navigate = useNavigate();

  const container = {
    // hidden: {  scale: 0 },
    // visible: {
    //   scale: 1,
    //   transition: {
    //     delayChildren: 1.2,
    //     staggerChildren: 0.5,
    //     type: "spring",
    //     stiffness: 100,
    //     default: { duration: 1 },
    //   },
    // },
  };

  const item = {
    // hidden: { y: 20, opacity: 0 },
    // visible: {
    //   y: 0,
    //   opacity: 1,
    // },
  };

  const variantsTitle = {
    // visible: {
    //   opacity: 1,
    //   x: 0,
    //   transition: {
    //     delay: 0.8,
    //     x: { type: "spring", stiffness: 100 },
    //     default: { duration: 1 },
    //   },
    // },
    // hidden: { opacity: 0, x: -200 },
  };

  const variantsAction = {
    // visible: {
    //   opacity: 1,
    //   x: 0,
    //   transition: {
    //     delay: 2.2,
    //     x: { type: "spring", stiffness: 100 },
    //     default: { duration: 1 },
    //   },
    // },
    // hidden: { opacity: 0, x: 200 },
  };

  return (
    <motion.div variants={container} initial="hidden" animate="visible">
      <Container>
        <div
          data-aos="zoom-in"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="1000"
        >
          <MainTitle title="what we service provide you ?" />
        </div>
        <Box mb={4} />
        <Grid
          container
          sx={{ backgroundColor: "#002488", borderRadius: "10px" }}
        >
          <Grid item xs={12} md={6}>
            <div data-aos="zoom-in" data-aos-duration="1000">
              <CardMedia
                component="img"
                height={`100%`}
                sx={{
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
                image={bg}
                alt="Live from space album cover"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            my="auto"
            sx={{
              background: `url(${line})`,
              backgroundPosition: "bottom",
              backgroundRepeat: "no-repeat",
              height: "100%",
            }}
          >
            <Box px={3} py={1}>
              <div data-aos="fade-up" data-aos-duration="1000">
                <Box>
                  <Typography component="span" variant="h4" color="#FBAE3C">
                    01.
                  </Typography>
                  <Typography component="span" variant="h6" color="white">
                    {" "}
                    Quality Consultation
                  </Typography>
                  <Divider sx={{ width: "20%", bgcolor: "#FBAE3C", my: 1 }} />
                  <Typography
                    color="white"
                    variant="body2"
                    sx={{ opacity: 0.8 }}
                  >
                    We’ve put water quality as one of our priorities, and for
                    this reason we made it easier on our clients to book a
                    consultation session on their water
                  </Typography>
                </Box>
              </div>
              <div data-aos="fade-up" data-aos-duration="1000">
                <Box my={5}>
                  <Typography component="span" variant="h4" color="#FBAE3C">
                    02.
                  </Typography>
                  <Typography component="span" variant="h6" color="white">
                    {" "}
                    Maintenance and Cleaning
                  </Typography>
                  <Divider sx={{ width: "20%", bgcolor: "#FBAE3C", my: 1 }} />
                  <Typography
                    color="white"
                    variant="body2"
                    sx={{ opacity: 0.8 }}
                  >
                    CH2O offers Weekly,Monthly and Quarterly visits to support
                    pool owners, managers, operators or any staff member with
                    knowledge and advise to maintain correct, safe and
                    economical facility management
                  </Typography>
                </Box>
              </div>
              <div data-aos="fade-up" data-aos-duration="1000">
                <Box>
                  <Typography component="span" variant="h4" color="#FBAE3C">
                    03.
                  </Typography>
                  <Typography component="span" variant="h6" color="white">
                    {" "}
                    Water test kit
                  </Typography>
                  <Divider sx={{ width: "20%", bgcolor: "#FBAE3C", my: 1 }} />
                  <Typography
                    color="white"
                    variant="body2"
                    sx={{ opacity: 0.8 }}
                  >
                    Using our Rapid Test kit we will help you discover what of
                    the following your water holds :<br />
                    Arsenic , Chloride ...
                  </Typography>
                </Box>
              </div>
              <div data-aos="fade-up" data-aos-duration="1000">
                <Box mt={5}>
                  <Button
                    variant="text"
                    endIcon={<ArrowForwardIcon />}
                    onClick={() => {
                      navigate("/services");
                    }}
                  >
                    Read more
                  </Button>
                </Box>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* <Box
        sx={{
          height: "100%",
          background: "rgb(126,201,245)",
          background:
            "linear-gradient(162deg, rgba(126,201,245,1) 0%, rgba(0,26,114,1) 100%)",
          display: "grid",
          placeItems: "center",
          borderRadius: "20px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          py: 5,
          mx: { sm: 0, md: 5 },
        }}
      >
        <Container>
          <Grid container display="flex" justifyContent="center" spacing={5}>
            <Grid item xs={12} md={8}>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={variantsTitle}
              >
                <MainTitle
                  title="what we service provide you ?"
                  color="white"
                />
              </motion.div>
            </Grid>
            <Grid
              item
              xs={4}
              my="auto"
              sx={{ display: { xs: "none", md: "flex" } }}
              justifyContent="end"
            >
              <motion.div
                initial="hidden"
                animate="visible"
                variants={variantsAction}
              >
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => {
                    navigate("/services");
                  }}
                >
                  Explore
                </Button>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <motion.div variants={item}>
                <Dcard
                  title="Quality Consultation"
                  description="We’ve put water quality as one of our priorities"
                  logo={qualityLogo}
                />
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <motion.div variants={item}>
                <Dcard
                  title="Maintenance and Cleaning"
                  description="We’ve put water quality as one of our priorities"
                  logo={maintenanceImg}
                />
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <motion.div variants={item}>
                <Dcard
                  title="Water test kit"
                  description="We’ve put water quality as one of our priorities"
                  logo={testingLogo}
                />
              </motion.div>
            </Grid>
            <Grid
              item
              xs={12}
              my="auto"
              sx={{ display: { xs: "flex", md: "none" } }}
              justifyContent="center"
              alignItems="center"
            >
              <motion.div
                initial="hidden"
                animate="visible"
                variants={variantsAction}
              >
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => {
                    navigate("/services");
                  }}
                >
                  Explore
                </Button>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </Box> */}
    </motion.div>
  );
};

export default Services;
