import { Container } from "@mui/system";
import Header from "../../components/Sections/Header";
import {
  Drawer,
  Toolbar,
  Typography,
  Button,
  Grid,
  Divider,
  Stack,
  Box,
  Skeleton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Ccard from "../../components/cards/Ccard";
import bla from "../../assets/images/pool-equipement.jpg";
import useFetch from "../../hooks/useFetch";
import Skelton from "../../components/cards/Ccard/Skelton";
import { useParams, useLocation,Link } from "react-router-dom";
import OtherCategories from "./Sections/OthersCategories";
import ImageGallery from "react-image-gallery";
import { Interweave } from "interweave";
import SubTitle from "../../components/Typography/SubTitle";
import { useEffect } from "react";
const URL_STORAGE = "https://admin.ch2olb.com";

const SingleItem = () => {
  const params = useParams();
  const { data, isPending } = useFetch(
    "https://admin.ch2olb.com/api/getSingleProduct1/" + params.slug
  );

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const breadcrumbs = [
    <Link key="1" to="/" style={{ color:'white' }}>
      Home
    </Link>,
    <Link  key="2" to="/products" style={{ color:'white' }}>
      Products
    </Link>,
    <Link
      key="3"
      to={data && `/items/${data.data.content.category.slug}`}
      style={{ color:'white' }}
    >
      {data ? data.data.content.category.name : <Skeleton width={100} variant="text" />}
    </Link>,
    <Typography key="5" color="white">{data ? data.data.content.name : <Skeleton width={100} variant="text" />}</Typography>,
  ];
  return (
    <>
      <Header
        title="Products"
        breadcrumbs={breadcrumbs}
        description="We help people improve and enjoy their water"
      />
      <Box my={10}>
        <Container>
          <Grid container spacing={2}>
            {isPending && (
              <>
                <Grid item xs={6}>
                  <Skeleton variant="rectangular" height={300} />
                </Grid>
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    width={`60%`}
                    sx={{ py: 2, mb: 2 }}
                  />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" width={`60%`} />
                  <Skeleton variant="text" width={`20%`} sx={{ mt: 2 }} />
                </Grid>
              </>
            )}

            {data && (
              <>
                <Grid item xs={12} md={6}>
                  <ImageGallery items={data.data.gallery} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h3" color="black">{data.data.content.name}</Typography>
                  <Typography variant="body1" py={2} component="div" color="black" sx={{ opacity:0.9 }}>
                    <Interweave content={data.data.content.description} />
                  </Typography>
                  <Typography variant="body1" sx={{ color: "green" }} pt={2}>
                    {data.data.content.price} $
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Box my={10} />
          <SubTitle subtitle="Related Products" />
          <Box my={5} />
          <Grid container spacing={2}>
            {isPending && (
              <>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <Skelton />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <Skelton />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <Skelton />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <Skelton />
                </Grid>
              </>
            )}
            {data &&
              (data.data.relatedProduct.length > 0 ? (
                data.data.relatedProduct.map((res, index) => {
                  return (
                    <Grid item xs={6} sm={4} md={4} lg={3} key={index}>
                      <Ccard
                        title={res.name}
                        subtitle={`${res.price} $`}
                        image={`${URL_STORAGE}/${res.photo}`}
                        actionUrl={`/item/${res.slug}`}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Oops ! No related product found.
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default SingleItem;
