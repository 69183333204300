import React from "react";
import { Box } from "@mui/material";
import Header from "./Header";
import Welcome from "./Welcome";
import Services from "./Services";
import Help from "./Help";
import Shop from "./Shop";
import Work from "./Work";
import Inspiration from "./Inspiration";

const Home = () => {
  
  return (
    <div>
      <Header />
      <Box my={5} />
      <Welcome />
      <Box my={20} />
      <Services />
      <Box my={20} />
      <Box sx={{ backgroundColor: "#f3f4f5" }}>
        <Help />
        <Box my={20} />
        {/* <Shop />
      <Box my={20} /> */}
        <Work />
        <Box my={20} />
        <Inspiration />
      </Box>
    </div>
  );
};
export default Home;
