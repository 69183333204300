import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  IconButton,
  Button,
  CardActions,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Ccard = ({ title, subtitle, image, actionUrl }) => {
  const navigate = useNavigate();
  return (
    <Card sx={{ height: "100%", borderRadius: "10px" }}>
      <CardMedia
        component="img"
        sx={{ width: "100%" }}
        height="200px"
        image={image}
        alt={`Ch2o lebanon ${title}`}
      />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" color="black">
              {title}
            </Typography>
            <Typography variant="body2" sx={{ color: "green" }} pt={2}>
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          startIcon={<VisibilityIcon />}
          sx={{ color: "#FBAE3C" }}
          onClick={() => {
            navigate(actionUrl);
          }}
        >
          Explore
        </Button>
      </CardActions>
    </Card>
  );
};
export default Ccard;
