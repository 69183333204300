import React from "react";
import { Box, Typography, Container, Grid, Button, Link } from "@mui/material";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import MainTitle from "../../components/Typography/MainTitle";
import { motion } from "framer-motion";
import sssplatter from "../../assets/images/sssplatter.svg";
import bg from "../../assets/images/home.jpg";

const videoSrc = {
  type: "video",
  sources: [
    {
      src: "-4hhAscrQ2A",
      provider: "youtube",
    },
  ],
};

const Welcome = () => {
  return (
    <Box
      sx={{
        background: `url(${sssplatter})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left",
        height: "auto",
        py: 20,
      }}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} my="auto">
            <div
              data-aos="zoom-in"
              data-aos-anchor-placement="bottom-bottom"
              data-aos-duration="1000"
            >
              <Typography
                variant="h3"
                color="black"
                sx={{ textTransform: "uppercase" }}
              >
                <span style={{ color: "#FBAE3C" }}>Water</span> you aiming for ?
              </Typography>
            </div>
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
              data-aos-duration="1500"
            >
              <Typography variant="body1" py={4} color="black" component="div">
                We can Confidently tell you that we aim to
                deliver the most efficient and effective utility services of
                water and wastewater, respecting the natural environment in the
                process.
              </Typography>
              {/* <Button variant="contained">About Us</Button> */}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div data-aos="zoom-in-up" data-aos-duration="2000">
              <Box style={{ borderRadius: "10px", overflow: "hidden" }}>
                {/* <Plyr source={videoSrc} /> */}
                <img src={bg} style={{ backgroundSize:'cover',backgroundPostion:'center',maxHeight:'400px',width:'100%',borderRadius: "10px", }} />
              </Box>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default Welcome;
