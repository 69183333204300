import { Container } from "@mui/system";
import Contents from "./Sections/Contents";
import Header from "../../components/Sections/Header";
import { Box, Divider, Grid, Link, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import bla3 from "../../assets/images/ultra.jpg";

const Single = () => {
  const breadcrumbs = [
    <Link underline="hover" key="1" href="/" color="inherit">
      Home
    </Link>,
    <Link underline="hover" key="2" href="/" color="inherit">
      Water solutions
    </Link>,
    <Typography key="3">Slug</Typography>,
  ];
  return (
    <>
      <Header title="Water Solutions" breadcrumbs={breadcrumbs} />
      <Container>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Card sx={{ width: "100%", borderRadius: "20px" }}>
                <CardMedia
                  component="div"
                  alt="green iguana"
                  image={bla3}
                  sx={{
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    width: "100%",
                    height: "300px",
                  }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Lizard
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    Water softners Hard water is an issue that most people face
                    at their homes, with itchy skin, dull hair, water heater
                    malfunctions and stiff laundry. With CH2O’S Water Softeners,
                    problems solved. Softener combo 1(Residential). Softener
                    combo 2(Residential). CH2O Highflow Softener 1. CH2O
                    Industrial Highflow Softener 2. Resin Combo (Sustainable). 1
                    - Softener combo 1 & 2(Residential) Produces 1000 Ltr/Hour
                    and 1500 Ltr/Hour for system 2, of Soft Water. Prevents hard
                    water scale to build up in your pipes and appliances, and
                    helps them live longer. Maintains a softer water use,
                    softens the skin and laundry. We have customized 3 systems
                    for you to choose based on your daily consumption. PROUDLY
                    ASSEMBLED BY CH2O IN LEBANON 2 - CH2O Highflow Softener 1
                    Produces 2000 Ltr/Hour, of Soft Water. Prevents hard water
                    scale to build up in your pipes and appliances, and helps
                    them live longer. Maintains a softer water use, softens the
                    skin and laundry. We have customized 3 systems for you to
                    choose based on your daily consumption. Used for whole house
                    and building water softening. PROUDLY ASSEMBLED BY CH2O IN
                    LEBANON 3 - CH2O Highflow Softener 2 Produces 3000 Ltr/Hour,
                    of Soft Water. Prevents hard water scale to build up in your
                    pipes and appliances, and helps them live longer. Maintains
                    a softer water use, softens the skin and laundry. We have
                    customized 3 systems for you to choose based on your daily
                    consumption. Used for whole house and building water
                    softening. PROUDLY ASSEMBLED BY CH2O IN LEBANON 4 - Resin
                    Combo (Sustainable) CH2O recognized that it’s time for a
                    sustainable and affordable softening unit that uses a resin
                    high flow cartridge, and a sediment filter housing. The
                    Resin cartridge can be refreshed by dipping it into sodium
                    so it exchanges its elements and gets ready for use. PROUDLY
                    ASSEMBLED BY CH2O IN LEBANON Contains a resin high flow
                    catridge Eliminates and regulates high levels of calcium
                    Uses ion exchange method of treatment Can be easily washed
                    using sodium Guaranteed life 2 years life time
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ width: "100%", borderRadius: "20px" }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    More water solutions
                  </Typography>
                  <Divider sx={{ width:'100%',backgroundColor:'#FBAE3C',height:'1px' }} />
                  <List component="nav" aria-label="main mailbox folders">
                    <ListItemButton>Ultra shlek</ListItemButton>
                    <ListItemButton>Ultra shlek</ListItemButton>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
export default Single;
