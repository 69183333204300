import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../../assets/images/logo.png";
import { NavLink as RouterLink } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
import "../../assets/css/navLink.css";
import { useLocation } from "react-router-dom";

import Mobile from "./Mobile";

const pages = [
  {
    name: "Products",
    url: "/products",
    activateWhen: ["products", "items", "item"],
  },
  // {
  //   name: "Solutions",
  //   url: "/water-solutions",
  //   activateWhen: ["water-solutions"],
  // },
  {
    name: "Work",
    url: "/work",
    activateWhen: ["work", "project"],
  },
  {
    name: "Inspiration",
    url: "/inspiration-gallery",
    activateWhen: ["inspiration-gallery"],
  },
  {
    name: "Services",
    url: "/services",
    activateWhen: ["services"],
  },
  // {
  //   name: "About",
  //   url: "/contactus",
  //   activateWhen: ["contactus"],
  // },
  {
    name: "Contact us",
    url: "/contactus",
    activateWhen: ["contactus"],
  },
];

const Navbar = () => {
  const [isScrolled, setIsScrolled] = React.useState(false);
  const location = useLocation();
  const pathName = location.pathname.split("/");
  React.useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 100;
      setIsScrolled(show);
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <AppBar
      elevation={isScrolled ? 1 : 0}
      sx={{
        top:0,
        backgroundColor: isScrolled ? "#002488" : "transparent",
        background: isScrolled ? "rgb(90,126,255)" : "transparent",
        background: isScrolled
          ? "linear-gradient(130deg, rgba(90,126,255,1) 0%, rgba(0,36,136,1) 100%)"
          : "transparent",
        filter: isScrolled ? "1.5rem" : 0,
        py: { xs: 2, md: 2 },
        height: "90px",
      }}
      position="fixed"
    >
      <Container>
        <Toolbar>
          {/* Small Menu */}
          <Typography
            component="div"
            sx={{
              display: { xs: "flex", lg: "none", flexGrow: 1 },
            }}
          >
            <RouterLink to="/">
              <img src={Logo} height="40" width="80" />
            </RouterLink>
          </Typography>
          <Box
            sx={{ display: { xs: "flex", lg: "none", justifyContent: "end" } }}
          >
            <Mobile />
          </Box>
          {/* End Small Menu */}
          {/* Large screen */}
          <Typography
            component="div"
            sx={{
              display: { xs: "none", lg: "flex" },
            }}
          >
            <RouterLink to="/">
              <img src={Logo} height="40" width="80" />
            </RouterLink>
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", lg: "flex", justifyContent: "end" },
            }}
          >
            {pages.map((page, index) => (
              <RouterLink
                to={page.url}
                key={index}
                className={`customLink ${
                  page.activateWhen.includes(pathName[1]) && "active"
                }`}
              >
                {page.name}
              </RouterLink>
            ))}
          </Box>
          {/* Logo Large screen */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
