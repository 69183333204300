import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const themes = () => {
  const themes = createTheme({
    
    typography: {
      fontFamily: "Montserrat",
      h1: {
        fontWeight: 500,
      },
      h2: {
        fontWeight: 500,
      },
      h3: {
        fontWeight: 500,
      },
      h4: {
        fontWeight: 500,
        fontSize:35,
      },
      h5: {
        fontWeight: 500,
      },
      h6: {
        fontWeight: 500,
      },
      body1: {
        fontWeight: 400,
        color:'#808080',
      },
      body2: {
        fontWeight: 400,
        color:'#808080',
      },
      subtitle1: {
        fontWeight: 300,
        color:'#808080',
      },
      subtitle2: {
        fontWeight: 300,
        color:'#808080',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {},
          outlined: {
            color: "#FBAE3C",
            borderColor: "#FBAE3C",
            "&:hover": {
              color: "white",
              backgroundColor: "#FBAE3C",
              borderColor: "#FBAE3C",
            },
          },
          contained: {
            color: "white",
            paddingLeft:'32px',
            paddingRight:'32px',
            textTransform:'capitalize',
            backgroundColor: "#FBAE3C",
            fontSize:'16px',
            borderColor: "#FBAE3C",
            borderRadius: "5px",
            "&:hover": {
              backgroundColor: "#FBAE3C",
            },
          },
          text: {
            fontSize:'16px',
            color: "#FBAE3C",
            textTransform:'capitalize',
            
          },
        },
      },
    },
  });
  return responsiveFontSizes(themes);
};
export default themes;
