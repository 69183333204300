import React from "react";
import {
  Grid,
} from "@mui/material";
import Ucard from "../../../components/cards/Ucard";
import waterSol1 from "../../../assets/images/bg.jpg";
import bla3 from "../../../assets/images/ultra.jpg";
const Contents = () => {
  return (
    <>
      <Grid container spacing={5}>
        <Grid item md={4}>
          <Ucard
            title="Water softeners"
            description="Hard water is an issue that most people face at their homes, with itchy skin, dull hair, water heater malfunctions and stiff laundry. With CH2O’S Water Softeners, problems solved."
            logo={waterSol1}
          />
        </Grid>
        <Grid item md={4}>
          <Ucard
            title="Ultra-Filtrations Plants"
            description="Hard water is an issue that most people face at their homes, with itchy skin, dull hair, water heater malfunctions and stiff laundry. With CH2O’S Water Softeners, problems solved."
            logo={bla3}
          />
        </Grid>
        <Grid item md={4}>
          <Ucard
            title="Reverse Osmosis"
            description="Hard water is an issue that most people face at their homes, with itchy skin, dull hair, water heater malfunctions and stiff laundry. With CH2O’S Water Softeners, problems solved."
            logo={waterSol1}
          />
        </Grid>
        <Grid item md={4}>
          <Ucard
            title="Ultra-Filtrations Plants"
            description="Hard water is an issue that most people face at their homes, with itchy skin, dull hair, water heater malfunctions and stiff laundry. With CH2O’S Water Softeners, problems solved."
            logo={bla3}
          />
        </Grid>
        <Grid item md={4}>
          <Ucard
            title="Ultra-Filtrations Plants"
            description="Hard water is an issue that most people face at their homes, with itchy skin, dull hair, water heater malfunctions and stiff laundry. With CH2O’S Water Softeners, problems solved."
            logo={bla3}
          />
        </Grid>
        <Grid item md={4}>
          <Ucard
            title="Ultra-Filtrations Plants"
            description="Hard water is an issue that most people face at their homes, with itchy skin, dull hair, water heater malfunctions and stiff laundry. With CH2O’S Water Softeners, problems solved."
            logo={bla3}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default Contents;
