import Product from "./Product";
import WaterSolution from "./WaterSolution";
import React from "react";
import { Box, Container } from "@mui/material";
import MainTitle from "../../components/Typography/MainTitle";
import wave from "../../assets/images/whiteWave.svg";

const Help = () => {
  return (
    <Box
      sx={{ backgroundImage: `url(${wave})`, backgroundRepeat: "no-repeat" }}
    >
      <Container>
        <div
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <MainTitle
            title="How CH2O can help you ?"
            description="CH2O develops tailored solutions to help you reach a water quality."
          />
        </div>
      </Container>
      {/* <Box my={5} />
      <WaterSolution /> */}
      <Box my={5} />
      <Product />
    </Box>
  );
};

export default Help;
