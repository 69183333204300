import React from "react";
import bg from "../../assets/images/wave.svg";
import { Box, Typography, Grid } from "@mui/material";
import Typical from "react-typical";
import { motion } from "framer-motion";
import "../../assets/css/header.css";
const variantsText = {
  // visible: {
  //   opacity: 1,
  //   transition: {
  //     delay: 0.5,
  //     type: "spring",
  //     stiffness: 100,
  //     default: { duration: 1 },
  //   },
  // },
  // hidden: { opacity: 0 },
};

const Header = () => {
  return (
    // <Box
    //   sx={{
    //     marginTop: "90px",
    //     height: "800px",
    //     background: `url(${bg})`,
    //     display: "grid",
    //     placeItems: "center",
    //   }}
    // >
    //   <Grid container display="flex" justifyContent="center">
    //     <Grid item xs={12} md={5}>
    //       <Typography
    //         variant="h2"
    //         color="white"
    //         sx={{ mt: -25 }}
    //         textAlign="center"
    //       >
    //         <Typical steps={["FOR WATER  FOR LIFE "]} />
    //       </Typography>
    //       <motion.div
    //         initial="hidden"
    //         animate="visible"
    //         variants={variantsText}
    //       >
    //         <Typography
    //           variant="body1"
    //           color="white"
    //           textAlign="center"
    //         >
    //           Through our residential and business solutions in the water
    //           filtration and swimming pool technology , we help people improve
    //           and enjoy their water. if not for life's essential resource, then
    //           for what?
    //         </Typography>
    //       </motion.div>
    //     </Grid>
    //     <Grid item xs={12}>
    //       <div className="bubble small"></div>
    //       <div className="bubble s-medium"></div>
    //       <div className="bubble s-medium"></div>
    //       <div className="bubble s-medium"></div>
    //       <div className="bubble s-medium"></div>
    //       <div className="bubble medium"></div>
    //       <div className="bubble medium"></div>
    //       <div className="bubble medium"></div>
    //       <div className="bubble medium"></div>
    //       <div className="bubble large"></div>
    //       <div className="bubble large"></div>
    //       <div className="bubble large"></div>
    //       <div className="bubble large"></div>
    //       <div className="bubble small-l"></div>
    //       <div className="bubble small-l"></div>
    //     </Grid>
    //   </Grid>
    // </Box>
    <>
      <div className="header">
        <div className="inner-header flex">
          <Typography variant="h3" sx={{ px:{xs:2} }}>
            <Typical steps={["FOR WATER  FOR LIFE "]} />
          </Typography>
          <Typography variant="body1" color="white" textAlign="center" sx={{ px:{xs:2}}}>
            Through our residential and business solutions in the water
            filtration and swimming pool technology ,<br /> we help people
            improve and enjoy their water.
            <br /> if not for life's essential resource, then for what?
          </Typography>
        </div>
        <div>
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xlinkHref="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="0"
                fill="rgba(255,255,255,0.7"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="3"
                fill="rgba(255,255,255,0.5)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="5"
                fill="rgba(255,255,255,0.3)"
              />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </div>
    </>
  );
};

export default Header;
