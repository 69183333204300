import { Typography,Divider } from "@mui/material";

const SubTitle = ({ subtitle, color }) => {
  return (
    <>
      <Typography
        variant="h4"
        color={color ? color : "black"}
        py={2}
        sx={{ textTransform: "uppercase" }}
      >
        {subtitle}
      </Typography>
      <Divider sx={{ width: "20%", bgcolor: "#FBAE3C" }} />
    </>
  );
};
export default SubTitle;
