import { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Box, Container } from "@mui/system";
import { Typography, Grid, withTheme, Divider } from "@mui/material";

import Header from "../../components/Sections/Header";
import CheckIcon from "@mui/icons-material/Check";
import MainTitle from "../../components/Typography/MainTitle";
import CustomChip from "../../components/CustomChip";
import Ycard from "../../components/cards/Ycard";

import b1 from "../../assets/images/maintenance.jpg";
import b3 from "../../assets/images/quality.jpg";
import b2 from "../../assets/images/water-test.jpg";
import sssplatter from "../../assets/images/whiteWave.svg";
import bottomBg from "../../assets/images/whiteWaveBottom.svg";

const Services = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const breadcrumbs = [
    <Link key="1" to="/" style={{ color: "white" }}>
      Home
    </Link>,
    <Typography key="2" textAlign="center" color="white">
      Services
    </Typography>,
  ];
  return (
    <>
      <Header
        title="Services"
        breadcrumbs={breadcrumbs}
        description="WHAT WE SERVICE PROVIDE YOU ?"
      />
      <Box>
        <Box mt={5}>
          <Container>
            <Grid container spacing={4} py={5}>
              <Grid item xs={12} md={5}>
                <div data-aos="fade-up" data-aos-duration="1000">
                  <Box
                    sx={{
                      my: 5,
                      height: "400px",
                      width: "100%",
                      background: `url(${b3})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      boxShadow: "-40px -40px 0px 0px  #f3f4f5",
                      borderRadius: "20px",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={7} px={2}>
                <div data-aos="fade-up" data-aos-duration="1000">
                  <Typography
                    variant="h4"
                    color="black"
                    sx={{ textTransform: "uppercase" }}
                  >
                    <span style={{ color: "#FBAE3C" }}>Quality</span>{" "}
                    Consultation
                  </Typography>
                </div>
                <div data-aos="fade-up" data-aos-duration="1500">
                  <Typography variant="body1" py={3} color="black">
                    We’ve put water quality as one of our priorities.
                    <br /> <br />
                    For this reason we made it easier on our clients to book a
                    consultation session on their water through a test done by
                    us on site, to know what quality of water it really is, and
                    to be able to offer a{" "}
                    <Link to="/products"> tailored solution </Link> to make your
                    water a safe source for your potable, coffee, tea and
                    cooking use.
                    <br /> <br /> By reaching to CH2O, you’re taking a step
                    forward in the water perfection journey.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box
          sx={{
            backgroundColor: "#f3f4f5",
          }}
        >
          <Box
            sx={{
              background: `url(${sssplatter})`,
              backgroundRepeat: "no-repeat",
              height: "auto",
              py: 10,
            }}
          >
            <Container>
            <div data-aos="fade-up" data-aos-duration="1000">

              <Box
                sx={{
                  backgroundColor: "rgba(0,36,136,1)",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
              >
                <img
                  src={b1}
                  style={{
                    backgroundPosition: "center",
                    backgroundSuze: "cover",
                    height: "500px",
                    width: "100%",
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                  }}
                />
                <Box sx={{ px: 2, py: 5 }}>
                  <Typography
                    variant="h4"
                    color="white"
                    sx={{ textTransform: "uppercase" }}
                  >
                    Swimming Pool{" "}
                    <span style={{ color: "#FBAE3C" }}>Maintenance</span> &{" "}
                    <span style={{ color: "#FBAE3C" }}>Cleaning</span>
                  </Typography>
                  <Typography
                    py={5}
                    variant="body1"
                    color="white"
                    sx={{ opacity: 0.9 }}
                  >
                    CH2O offers Weekly,Monthly and Quarterly visits to support
                    pool owners, managers, operators or any staff member with
                    knowledge and advise to maintain correct, safe and
                    economical facility management.
                  </Typography>
                  <Typography
                    py={5}
                    variant="body1"
                    color="white"
                    sx={{ opacity: 0.9 }}
                  >
                    In our visit we will always complete the following task list
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <Ycard
                        title="Recirculation"
                        text="Clarity of water, flow rate, gutter drains and
                      circultation"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Ycard
                        title="Filtration"
                        text="Filter efficiency, Pump State, Pressure gages check"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Ycard
                        title="Heating"
                        text="Energy source, Heat exchanger status and thermostat"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Ycard
                        title="Water chemistry"
                        text="Measure PH and Chlorine levels and test for hardness"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Ycard
                        title="Training"
                        text="Training on operating and maintaining your facility"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Ycard title="Automation and control" />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Ycard
                        title="Problems and solutions"
                        text="eye redness, itching, odors and other"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              </div>
            </Container>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#f3f4f5",
          }}
        >
          <Box
            py={5}
            sx={{
              backgroundColor: "#f3f4f5",
              background: `url(${bottomBg})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom",
            }}
          >
            <Container>
            <div data-aos="fade-up" data-aos-duration="1000">

              <Grid container>
                <Grid md={5}>
                  <img
                    src={b2}
                    style={{
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "100%",
                      borderRadius: "10px",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  />
                </Grid>
                <Grid md={7} p={2}>
                  <Typography
                    variant="h4"
                    color="black"
                    sx={{ textTransform: "uppercase" }}
                  >
                    Water
                    <span style={{ color: "#FBAE3C" }}> test </span>
                    kit
                  </Typography>
                  <Typography
                    color="black"
                    py={2}
                    variant="body1"
                    sx={{ opacity: 0.9 }}
                  >
                    Using our Rapid Test kit we will help you discover what of
                    the following your water holds
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <CustomChip
                        text="Arsenic"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="Chloride"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="Total Coliform (bacteria)"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="Total Dissolved Solids (TDS)"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="Copper"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="Fluoride"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="Lead"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="Manganese"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="Nitrates"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="Nitrites"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="pH"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="Sulfate"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="Tannins"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="Turbidity"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="Total Iron"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomChip
                        text="Water Hardness"
                        icon={
                          <CheckIcon
                            sx={{ color: "rgba(0,36,136,1) !important" }}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              </div>
            </Container>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Services;
