import React from "react";
import bg from "../../assets/images/wave.svg";
import { Box, Typography, Container, Grid, Breadcrumbs } from "@mui/material";
import "../../assets/css/header.css";

const Header = ({ title, breadcrumbs, description }) => {
  return (
    <>
      <div className="header">
        <div className="inner-header flex">
          <Typography
            variant="h3"
            color="white"
            textAlign="center"
            sx={{ textTransform: "uppercase", mb: 2 }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            color="white"
            component="div"
            mt={1}
            mb={2}
            textAlign="center"
          >
            {description}
          </Typography>
          <Breadcrumbs
            separator="››"
            color="white"
            aria-label="breadcrumb"
            display="flex"
            justifyContent="center"
            alignItems="center"
            maxItems={3}
          >
            {breadcrumbs}
          </Breadcrumbs>
        </div>
        <div>
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xlinkHref="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="0"
                fill="rgba(255,255,255,0.7"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="3"
                fill="rgba(255,255,255,0.5)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="5"
                fill="rgba(255,255,255,0.3)"
              />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </div>
    </>
  );
};
export default Header;
