import { useEffect } from "react";
import { useLocation,Link } from "react-router-dom";
import { Container } from "@mui/system";
import { Typography, Grid, Box } from "@mui/material";
import Header from "../../components/Sections/Header";
import Icard from "../../components/cards/Icard";
import IcardSkelton from "../../components/cards/Icard/Skelton";
import useFetch from "../../hooks/useFetch";

const STORAGE_URL = "https://admin.ch2olb.com";

const Work = () => {
  const { isPending, error, data } = useFetch(
    "https://admin.ch2olb.com/api/getWorks"
  );
  
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  

  const breadcrumbs = [
    <Link to="/" style={{ color: "white" }} key="1">
      Home
    </Link>,
    <Typography key="2" color="white">Work</Typography>,
  ];
  return (
    <>
      <Header
        title="Work"
        breadcrumbs={breadcrumbs}
        description="We are proud to share some of the success cases of our valued customers."
      />
      <Box my={10}>
      <Container>
        <Grid container spacing={2}>
          {error && (
            <Grid item md={3}>
              <h1>OOPS ! Page not found!</h1>
            </Grid>
          )}
          {isPending && (
            <>
              <Grid item md={4} xs={12} lg={3}>
                <IcardSkelton />
              </Grid>
              <Grid item md={4} xs={12} lg={3}>
                <IcardSkelton />
              </Grid>
              <Grid item md={4} xs={12} lg={3}>
                <IcardSkelton />
              </Grid>
              <Grid item md={4} xs={12} lg={3}>
                <IcardSkelton />
              </Grid>
            </>
          )}

          {data &&
            data.data.map((res, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Icard title={res.name} image={`${STORAGE_URL}/${res.photo}`} subtitle={`${res.works_count} Project (s)`} actionUrl={`${res.slug}`}/>
                </Grid>
              );
            })}
        </Grid>
      </Container>
      </Box>
    </>
  );
};
export default Work;
