import React from "react";
import bg from "../../assets/images/wave.svg";
import b1 from "../../assets/images/bg.webp";
import { Box, Container, Grid, Button } from "@mui/material";

import Icard from "../../components/cards/Icard";
import IcardSkelton from "../../components/cards/Icard/Skelton";
import resorts from "../../assets/images/resorts.jpg";
import hotels from "../../assets/images/hotels.jpg";
import human from "../../assets/images/human.jpg";
import felt from "../../assets/images/water-filter-gallery.jpg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MainTitle from "../../components/Typography/MainTitle";
import Carousel from "react-grid-carousel";
import useFetch from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
const STORAGE_URL = "https://admin.ch2olb.com";

const Work = () => {
  const { isPending, error, data } = useFetch(
    "https://admin.ch2olb.com/api/getWorks"
  );
  const navigate = useNavigate();
  const array = [1, 2, 3, 4, 5];
  return (
    <Box>
      <Container>
        <Grid container>
          <Grid item xs={12} md={8}>
            <div data-aos="fade-right" data-aos-duration="1000">
              <MainTitle
                title="Discover Our Work"
                description=" We are proud to share some of the success cases of our valued
              customers."
              />
            </div>
          </Grid>

          <Grid
            item
            xs={4}
            my="auto"
            sx={{ display: { xs: "none", md: "flex" } }}
            justifyContent="end"
          >
            <div data-aos="fade-left" data-aos-duration="1000">
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={() => {
                  navigate("/work");
                }}
              >
                View All
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} mt={5}>
            <Carousel
              showDots
              cols={4}
              rows={1}
              gap={11}
              responsiveLayout={[
                {
                  breakpoint: 1200,
                  cols: 3,
                },
                {
                  breakpoint: 990,
                  cols: 2,
                },
              ]}
              mobileBreakpoint={600}
            >
              {isPending &&
                array.map((index) => {
                  return (
                    <Carousel.Item key={index}>
                      <IcardSkelton />
                    </Carousel.Item>
                  );
                })}
              {data &&
                data.data.map((res, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <div data-aos="zoom-in-up" data-aos-duration="1000">
                        <Icard
                          title={res.name}
                          image={`${STORAGE_URL}/${res.photo}`}
                          subtitle={`${res.works_count} Project (s)`}
                          actionUrl={`/work/${res.slug}`}
                        />
                      </div>
                    </Carousel.Item>
                  );
                })}
            </Carousel>
          </Grid>
          <Grid
            item
            xs={12}
            my="auto"
            sx={{ display: { xs: "flex", md: "none" } }}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              onClick={() => {
                navigate("/work");
              }}
            >
              View All
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default Work;
