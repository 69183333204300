import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
const Ucard = ({ title, description, logo }) => {
  return (
    <Card sx={{ width: "100%", borderRadius: "10px",border:'1px solid #f3f4f5',my:1}} elevation={1}>
      <CardMedia
        component="img"
        sx={{ borderRadius: "16px", p: 1 }}
        height="200"
        image={logo}
        alt="Live from space album cover"
      />
      <CardContent>
        <Typography component="div" variant="h5" pb={2}>
          {title}
        </Typography>
        <Typography variant="body2" pb={2}>
          {description}
        </Typography>
        
        <Button variant="text" endIcon={<ArrowForward />} > Read more</Button>
      </CardContent>
    </Card>
  );
};
export default Ucard;
