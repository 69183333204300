import { Container } from "@mui/system";
import Header from "../../components/Sections/Header";
import {
  Drawer,
  Toolbar,
  Typography,
  Skeleton,
  Grid,
  Divider,
  Stack,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Ccard from "../../components/cards/Ccard";
import bla from "../../assets/images/pool-equipement.jpg";
import useFetch from "../../hooks/useFetch";
import Skelton from "../../components/cards/Ccard/Skelton";
import { useParams } from "react-router-dom";
import OtherCategories from "./Sections/OthersCategories";
import OtherSubCategories from "./Sections/OtherSubCategories";
import { useLocation, Link } from "react-router-dom";
import { useEffect } from "react";
import { Interweave } from "interweave";
const URL_STORAGE = "https://admin.ch2olb.com";

const Item = () => {
  const params = useParams();
  const { data, isPending } = useFetch(
    "https://admin.ch2olb.com/api/getProducts1/" + params.slug
  );

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const breadcrumbs = [
    <Link to="/" key="1" style={{ color:'white' }}>
      Home
    </Link>,
    <Link to="/products" key="2" style={{ color:'white' }}>
      Products
    </Link>,
    <Link key="3" to={data && `/products/${data.data[0].main_category.slug}`} style={{ color:'white' }}>
      {data ? (
        data.data[0].main_category.name
      ) : (
        <Skeleton width={100} variant="text" />
      )}
    </Link>,
    <Typography key="3" style={{ color:'white' }}>
      {data ? data.data[0].name : <Skeleton width={100} variant="text" />}
    </Typography>,
  ];
  return (
    <>
      <Header
        title="Products"
        breadcrumbs={breadcrumbs}
        description={
          data ? (
            <Interweave content={data.data[0].description} />
          ) : (
            <Skeleton width={100} variant="text" />
          )
        }
      />
      <Box my={10}>
        <Container>
          <Stack direction="row" spacing={1}>
            <OtherCategories />
            {data && (
              <OtherSubCategories slug={data.data[0].main_category.slug} />
            )}
          </Stack>

          <Divider sx={{ width: "100%", my: 2 }} />

          <Grid container spacing={2}>
            {isPending && (
              <>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <Skelton />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <Skelton />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <Skelton />
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={3}>
                  <Skelton />
                </Grid>
              </>
            )}
            {data &&
              (data.data[0].products.length > 0 ? (
                data.data[0].products.map((res, index) => {
                  return (
                    <Grid item xs={12} sm={4} md={4} lg={3} key={index}>
                      <Ccard
                        title={res.name}
                        subtitle={`${res.price} $`}
                        image={`${URL_STORAGE}/${res.photo}`}
                        actionUrl={`/item/${res.slug}`}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Grid item xs={12}>
                  <Typography variant="h5" py={5} textAlign="center">
                    Oops ! No Products found.
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Item;
