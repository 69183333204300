import { Container } from "@mui/system";
import Header from "../../components/Sections/Header";
import {
  Drawer,
  Toolbar,
  Typography,
  Button,
  Grid,
  Divider,
  Box,
  Skeleton
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Tcard from "../../components/cards/Tcard";
import bla from "../../assets/images/pool-equipement.jpg";
import useFetch from "../../hooks/useFetch";
import Skelton from "../../components/cards/Tcard/Skelton";
import { useParams, useLocation,Link } from "react-router-dom";
import OtherCategories from "./Sections/OthersCategories";
import { useEffect } from "react";
import { Interweave } from "interweave";

const URL_STORAGE = "https://admin.ch2olb.com";

const Single = () => {
  const params = useParams();
  const { data, isPending } = useFetch(
    "https://admin.ch2olb.com/api/getCat/" + params.slug
  );

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const breadcrumbs = [
    <Link to="/" key="1" style={{ color:'white' }}>
      Home
    </Link>,
    <Link to="/products" key="2" style={{ color:'white' }}>
      Products
    </Link>,
    <Typography key="3" color="white" component="div">{data ? data.data[0].name : <Skeleton width={100} variant="text" />}</Typography>,
  ];
  return (
    <>
      <Header
        title="Products"
        breadcrumbs={breadcrumbs}
        description={
          data ? <Interweave content={data.data[0].description} /> : <Skeleton width={100} variant="text" />
        }
      />
      <Box my={10}>
        <Container>
          <OtherCategories />

          <Divider sx={{ width: "100%", my: 2 }} />

          <Grid container spacing={2}>
            {isPending && (
              <>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Skelton />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Skelton />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Skelton />
                </Grid>
              </>
            )}
            {data &&
              (data.data[0].categories.length > 0 ? (
                data.data[0].categories.map((res, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                      <Tcard
                        title={res.name}
                        subtitle={`${res.products_count} Products`}
                        image={`${URL_STORAGE}/${res.photo}`}
                        urlAction={`/items/${res.slug}`}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Oops ! No Categories found.
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Single;
