import { Typography, Chip } from "@mui/material";

const CustomChip = ({ text, icon }) => {
  return (
    <>
      
      <Typography variant="body1" color="black" >
      {icon}<strong>{text}</strong>
      </Typography>
    </>

    // <Chip
    //   label={}
    //   sx={{
    //     color: "white",
    //     backgroundColor: "#001a72",
    //     borderRadius: "10px",
    //     px: 2,
    //   }}
    //   icon={icon}
    // />
  );
};
export default CustomChip;
