import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import { Box } from "@mui/system";
import { CardMedia, Grid, Stack } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useState } from "react";
import Masonry from "@mui/lab/Masonry";
import { AnimatePresence, motion } from "framer-motion";
import { ImageList, ImageListItem } from "@mui/material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="fade" ref={ref} {...props} />;
});
const standardImages = [
  {
    original:
      "https://admin.ch2olb.com/storage/images/work/gallery/1652349327_qyMjj.jpg",
    thumbnail:
      "https://admin.ch2olb.com/storage/images/work/gallery/1652349327_qyMjj.jpg",
  },
  {
    original:
      "https://admin.ch2olb.com/storage/images/work/gallery/1652350319_5g6fb.png",
    thumbnail:
      "https://admin.ch2olb.com/storage/images/work/gallery/1652350319_5g6fb.png",
  },
  {
    original:
      "https://admin.ch2olb.com/storage/images/work/gallery/1652349855_sl8OM.jpg",
    thumbnail:
      "https://admin.ch2olb.com/storage/images/work/gallery/1652349855_sl8OM.jpg",
  },
  {
    original:
      "https://admin.ch2olb.com/storage/images/work/gallery/1652349828_oed07.jpg",
    thumbnail:
      "https://admin.ch2olb.com/storage/images/work/gallery/1652349828_oed07.jpg",
  },
];
export default function LightBox({ images }) {
  const [open, setOpen] = React.useState(false);
  const [current, setCurrent] = useState(0);
  const [gallery, setGallery] = useState(standardImages);

  React.useEffect(() => {
    if (images !== undefined) {
      setGallery(images);
      console.log("1");
    } else {
      setGallery(standardImages);
      console.log("2");
    }
  }, []);

  const handleClickOpen = (index) => {
    setCurrent(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    if (current === gallery.length - 1) {
      setCurrent(0);
    } else {
      setCurrent(current + 1);
    }
  };
  const handlePrevious = () => {
    if (current === 0) {
      setCurrent(gallery.length - 1);
    } else {
      setCurrent(current - 1);
    }
  };

  return (
    <div>
      <>
        <Box>
          <Grid container spacing={1}>
            {gallery.map((item, index) => (
              <Grid item xs={12} md={6} key={index}>
                <div data-aos="zoom-in-up" data-aos-duration="1000">
                  <CardMedia
                    image={`${item.original}`}
                    sx={{
                      height: "300px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      transition: "transform 1s",
                      ":hover": {
                        transform: "scale(1.01)",
                      },
                    }}
                    component="img"
                    className="img-masonry"
                    onClick={() => {
                      handleClickOpen(index);
                    }}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar
            sx={{
              position: "relative",
              backgroundColor: "rgb(31,41,55)",
              height: "10%",
            }}
            elevation="0"
            onClick={handleClose}
          >
            <Toolbar>
              <Box>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              backgroundColor: "rgb(31,41,55)",
              height: "100%",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                md={2}
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  edge="start"
                  aria-label="close"
                  sx={{ color: "white" }}
                  onClick={handlePrevious}
                >
                  <ArrowBackIosNewIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Typography
                    textAlign="center"
                    variant="h6"
                    sx={{ color: "white" }}
                    pb={2}
                  >
                    {current + 1} / {gallery.length}
                  </Typography>
                  <AnimatePresence>
                    <motion.img
                      src={gallery[current].original}
                      initial={{ x: 300, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -300, opacity: 0 }}
                      transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 },
                      }}
                      style={{
                        backgroundSize: "auto",
                        borderRadius: "10px",
                        maxHeight: "70vh",
                      }}
                    />
                  </AnimatePresence>
                </Box>
              </Grid>
              <Grid
                item
                md={2}
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  edge="start"
                  sx={{ color: "white" }}
                  aria-label="close"
                  onClick={handleNext}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
                mt={5}
              >
                <Stack spacing={2} direction="row">
                  <IconButton
                    edge="start"
                    onClick={handlePrevious}
                    aria-label="close"
                    sx={{ color: "white" }}
                  >
                    <ArrowBackIosNewIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    sx={{ color: "white" }}
                    onClick={handleNext}
                    aria-label="close"
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </>
    </div>
  );
}
