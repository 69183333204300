import { Container } from "@mui/system";
import Header from "../../components/Sections/Header";
import {  Typography, Grid, Box } from "@mui/material";
import Tcard from "../../components/cards/Tcard";
import useFetch from "../../hooks/useFetch";
import Skelton from "../../components/cards/Tcard/Skelton";
import { useLocation ,Link } from "react-router-dom";
import { useEffect } from "react";

const URL_STORAGE = "https://admin.ch2olb.com";

const Product = () => {
  const { data, isPending } = useFetch(
    "https://admin.ch2olb.com/api/getAllMainCats"
  );

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const breadcrumbs = [
    <Link to="/" style={{ color:'white' }} key="1">
      Home
    </Link>,
    <Typography key="2" color="white">Products</Typography>,
  ];
  return (
    <>
      <Header
        title="Products"
        breadcrumbs={breadcrumbs}
        description="We help people improve and enjoy their water"
      />
      <Box my={10}>
        <Container>
          <Grid container spacing={2}>
            {isPending && (
              <>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Skelton />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Skelton />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Skelton />
                </Grid>
              </>
            )}
            {data &&
              data.data.map((res, index) => {
                return (
                  <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                    <Tcard
                      title={res.name}
                      subtitle={`${res.categories_count} Categories`}
                      image={`${URL_STORAGE}/${res.photo}`}
                      urlAction={`/products/${res.slug}`}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Product;
