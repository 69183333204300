import React from "react";
import { Box, Container, Grid, Button } from "@mui/material";
import wave from "../../assets/images/whiteWaveBottom.svg";
import resorts from "../../assets/images/resorts.jpg";
import hotels from "../../assets/images/hotels.jpg";
import human from "../../assets/images/human.jpg";
import MainTitle from "../../components/Typography/MainTitle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LightBox from "../../components/LightBox";
import { useNavigate } from "react-router-dom";

const Inspiration = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundImage: `url(${wave})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
      }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8}>
            <div data-aos="fade-right" data-aos-duration="1000">
              <MainTitle
                title="We are also your source of inspiration"
                description="Browse our inspiration gallery and dream with your eyes open."
              />
            </div>
          </Grid>
          <Grid
            item
            xs={4}
            my="auto"
            sx={{ display: { xs: "none", md: "flex" } }}
            justifyContent="end"
          >
            <div data-aos="fade-left" data-aos-duration="1000">
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                onClick={() => {
                  navigate("/inspiration-gallery");
                }}
              >
                View All
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} my={5}>
            
              <LightBox />
          </Grid>
          <Grid
            item
            xs={12}
            my="auto"
            sx={{ display: { xs: "flex", md: "none" } }}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              onClick={() => {
                navigate("/inspiration-gallery");
              }}
            >
              View All
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Inspiration;
