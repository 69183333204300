import { Typography } from "@mui/material";

const MainTitle = ({ title, description, color }) => {
  return (
    <>
      <Typography
        variant="h4"
        color={color ? color : "black"}
        py={2}
        px={2}
        sx={{
          borderLeft: 10,
          borderColor: "#FBAE3C",
          borderRadius: "2px",
          textTransform: "uppercase",
          // textShadow: '3px 4px 7px rgba(81,67,21,0.8)',
        }}
      >
        {title}
      </Typography>
      {description && (
        <Typography variant="body1" color="black" py={2}>
          {description}
        </Typography>
      )}
    </>
  );
};

export default MainTitle;
