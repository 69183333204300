import { Card, CardContent, Typography, Button, Stack } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import mesh from "../../../assets/images/mesh.png";
const Tcard = ({ title, image, subtitle, urlAction }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        width: "100%",
        height: "230px",
        borderRadius: "10px",
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "end",
        alignItems: "end",
        my: 1,
        cursor:'pointer',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
      }}
      elevation={1}
      onClick={() => {
        navigate(urlAction);
      }}
    >
      <CardContent
        sx={{
          width: "60%",
          height: "100%",
          // background:
          //   "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
          //   "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
          background: `url(${mesh})`,
          opacity: 0.95,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          transition: "width 1s",
          ":hover": {
            width: "100%",
          },
        }}
      >
        <Typography variant="h6" color="white">
          {title}
        </Typography>
        <Typography color="white" variant="body2" sx={{ opacity: 0.7 }} pt={1}>
          {subtitle}
        </Typography>
        <Stack direction="row" alignItems="center"></Stack>
      </CardContent>
    </Card>
  );
};
export default Tcard;
