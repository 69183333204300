import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Skelton = ({ withSubtitle }) => {
  return (
    <Card
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      <Skeleton
        variant="rectangular"
        width={`100%`}
        height={250}
        sx={{ borderRadius: "20px" }}
      />
      <CardContent>
        <Grid container>
          <Grid item xs={9}>
            <Skeleton width="80%" />
            {withSubtitle && (
              <Skeleton animation="wave" height={10} width="60%" />
            )}
          </Grid>
          <Grid item xs={3} justifyContent="end" alignContent="end">
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default Skelton;
