import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useFetch from "../../../hooks/useFetch";
import { Grid } from "@mui/material";
import Scard from "../../../components/cards/Scard";
import ScardSkelton from "../../../components/cards/Scard/Skelton";
const STORAGE_URL = "https://admin.ch2olb.com";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function OtherCategories() {
  const { isPending, error, data } = useFetch(
    "https://admin.ch2olb.com/api/getAllMainCats"
  );
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        endIcon={<KeyboardArrowDownIcon />}
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          color: "rgb(55,65,8)",
          borderColor: "rgb(55,65,8)",
          borderRadius: "20px",
          py: 1,
          "&:hover": {
            backgroundColor: "inherit",
            color: "rgb(55,65,8)",
            borderColor: "rgb(55,65,8)",
          },
        }}
      >
        Other Categories
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ width:'100%' }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Discover other categories
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container >
            {error && (
              <Grid item md={3}>
                <h1>OOPS ! Page not found!</h1>
              </Grid>
            )}
            {isPending && (
              <>
                <Grid item xs={12} sm={6} md={6} lg={6} >
                  <ScardSkelton />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <ScardSkelton />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <ScardSkelton />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <ScardSkelton />
                </Grid>
              </>
            )}

            {data &&
              data.data.map((res, index) => {
                return (
                  <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                    <Scard
                      title={res.name}
                      image={`${STORAGE_URL}/${res.photo}`}
                      subtitle={`${res.categories_count} Categories`}
                      actionUrl={`/products/${res.slug}`}
                      handleCloseModal={handleClose}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
