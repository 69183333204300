import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

const Icard = ({ title, subtitle, image, actionUrl }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: "100%",
          transition: "transform 1s",
          borderRadius: "20px",
          ":hover": {
            transform: "scale(1.01)",
          },
        }}
        height="250px"
        image={image}
        alt={`Ch2o lebanon ${title}`}
      />
      <CardContent>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="h6" color="black">
              {title}
            </Typography>
            <Typography variant="caption">{subtitle}</Typography>
          </Grid>

          <Grid item xs={3} justifyContent="start">
            <IconButton
              color="warning"
              aria-label={`Go to projects of ${title}`}
              onClick={() => {
                navigate(actionUrl);
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default Icard;
