import { Card, CardContent, Skeleton } from "@mui/material";

const Skelton = () => {
  return (
    <Card
      sx={{
        width: "100%",
        backgroundColor: "transparent",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Skeleton
          width="100%"
          animation="wave"
          height={400}
          sx={{
            borderRadius: "10px",
          }}
        />
      </CardContent>
    </Card>
  );
};
export default Skelton;
