import { useEffect } from "react";
import { Container } from "@mui/system";
import { Grid, Typography, Divider, Box, Skeleton } from "@mui/material";
import { useParams, Link, useLocation } from "react-router-dom";
import Header from "../../components/Sections/Header";
import Icard from "../../components/cards/Icard";
import IcardSkelton from "../../components/cards/Icard/Skelton";
import OtherCategories from "./Sections/OtherCategorie";
import useFetch from "../../hooks/useFetch";

const STORAGE_URL = "https://admin.ch2olb.com";

const Single = () => {
  const params = useParams();

  const { error, isPending, data } = useFetch(
    "https://admin.ch2olb.com/api/getProjectsBySlug/" + params.slug
  );
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const breadcrumbs = [
    <Link to="/" style={{ textDecoration: "none", color: "white" }} key="1">
      Home
    </Link>,
    <Link to="/work" style={{ textDecoration: "none", color: "white" }} key="2">
      Work
    </Link>,
    <Typography key="3" color="white">
      {data ? data.data[0].name : <Skeleton width={100} variant="text" />}
    </Typography>,
  ];
  return (
    <>
      <Header
        title="Work"
        description="We are proud to share some of the success cases of our valued customers."
        breadcrumbs={breadcrumbs}
      />
      <Box my={10}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} mb={5}>
              <OtherCategories />
            </Grid>
            <Divider sx={{ width: "100%" }} />
            {error && <Grid>OOPS ! Page not found!</Grid>}
            {isPending && (
              <>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <IcardSkelton withSubtitle={false} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <IcardSkelton withSubtitle={false} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <IcardSkelton withSubtitle={false} />
                </Grid>
              </>
            )}
            {data &&
              data.data[0].works.map((res, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <Icard
                      title={res.name}
                      image={`${STORAGE_URL}/${res.photo}`}
                      actionUrl={`/project/${res.slug}`}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Single;
