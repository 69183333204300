import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  IconButton,
  Button,
  CardActions,
  Skeleton,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Skelton = ({ title, subtitle, image, actionUrl }) => {
  const navigate = useNavigate();
  return (
    <Card sx={{ height: "100%", borderRadius: "10px" }}>
      <Skeleton
        variant="rectangular"
        width={`100%`}
        height={200}
      />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
          <Skeleton width="80%" />
          <Skeleton animation="wave" height={10} width="60%" />

          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Skeleton width="30%" />
      </CardActions>
    </Card>
  );
};
export default Skelton;
