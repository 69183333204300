import Layout from "../layouts";
import Home from "../pages/Home";
import Product from "../pages/Product";
import SingleProduct from "../pages/Product/Single";
import ProductItem from "../pages/Product/Item";
import ProductSingleItem from "../pages/Product/SingleItem";
import WaterSolution from "../pages/WaterSolution";
import SingleWaterSolution from "../pages/WaterSolution/Single";
import Work from "../pages/Work";
import SingleWork from "../pages/Work/Single";
import Project from "../pages/Work/Project";
import Services from "../pages/Services";
import Inspiration from "../pages/Inspiration";
import Contactus from "../pages/Contactus";

const MainRoutes = {
  path: "/",
  element: <Layout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/products",
      element: <Product />,
    },
    {
      path: "/products/:slug",
      element: <SingleProduct />,
    },
    {
      path: "/items/:slug",
      element: <ProductItem />,
    },
    {
      path: "/item/:slug",
      element: <ProductSingleItem />,
    },
    {
      path: "/water-solutions",
      element: <WaterSolution />,
    },
    {
      path: "/water-solutions/:slug",
      element: <SingleWaterSolution />,
    },
    {
      path: "/work",
      element: <Work />,
    },
    {
      path: "/work/:slug",
      element: <SingleWork />,
    },
    {
      path: "/project/:slug",
      element: <Project />,
    },
    {
      path: "/inspiration-gallery",
      element: <Inspiration />,
    },
    {
      path: "/services",
      element: <Services />,
    },
    {
      path: "/contactus",
      element: <Contactus />,
    },
  ],
};
export default MainRoutes;
