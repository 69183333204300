import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
  Skeleton,
} from "@mui/material";

const Skelton = () => {
  return (
    <Card
      sx={{
        borderRadius: "20px",
        width: "100%",
        bacgkroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      <CardContent sx={{ display: "flex", direction: "row" }}>
        <Skeleton
          variant="rectangular"
          height="50px"
          sx={{ borderRadius: "20px", width: "50px" }}
        />
        <Box px={2} sx={{ width:'100%' }}>
          <Skeleton width="100%" />
          <Skeleton animation="wave" height={10} width="60%" />
        </Box>
      </CardContent>
    </Card>
  );
};
export default Skelton;
